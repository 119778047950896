import type { ThemeDefinition } from 'vuetify';
import colors from 'vuetify/util/colors';

export function isOnEcDomain() {
  return window.location.host.includes('ec.mosaic');
}

export const defaultTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#3EA0AB',
    secondary: 'rgb(158, 158, 158)',
    accent: colors.orange.base,
    accentLight: colors.orange.lighten1,
    error: colors.red.base,
  },
};

export const earlyCareersTheme = {
  dark: false,
  colors: {
    primary: '#592e59',
    secondary: 'rgb(158, 158, 158)',
    accent: colors.orange.base,
    accentLight: colors.orange.lighten1,
    error: colors.red.base,
  },
};

export const calendarColors = [
  {
    backgroundColor: colors.red.lighten1,
    dragBackgroundColor: colors.red.base,
    borderColor: colors.red.base,
  },
  {
    backgroundColor: colors.blue.lighten1,
    dragBackgroundColor: colors.blue.base,
    borderColor: colors.blue.base,
  },
  {
    backgroundColor: colors.green.lighten1,
    dragBackgroundColor: colors.green.base,
    borderColor: colors.green.base,
  },
  {
    backgroundColor: colors.orange.lighten1,
    dragBackgroundColor: colors.orange.base,
    borderColor: colors.orange.base,
  },
  {
    backgroundColor: colors.brown.lighten1,
    dragBackgroundColor: colors.brown.base,
    borderColor: colors.brown.base,
  },
  {
    backgroundColor: colors.deepPurple.lighten1,
    dragBackgroundColor: colors.deepPurple.base,
    borderColor: colors.deepPurple.base,
  },
  {
    backgroundColor: colors.yellow.lighten1,
    dragBackgroundColor: colors.yellow.base,
    borderColor: colors.yellow.base,
  },
  {
    backgroundColor: colors.pink.lighten1,
    dragBackgroundColor: colors.pink.base,
    borderColor: colors.pink.base,
  },
  {
    backgroundColor: colors.cyan.lighten1,
    dragBackgroundColor: colors.cyan.base,
    borderColor: colors.cyan.base,
  },
  {
    backgroundColor: colors.lime.lighten1,
    dragBackgroundColor: colors.lime.base,
    borderColor: colors.lime.base,
  },
  {
    backgroundColor: colors.amber.lighten1,
    dragBackgroundColor: colors.amber.base,
    borderColor: colors.amber.base,
  },
  {
    backgroundColor: colors.purple.lighten1,
    dragBackgroundColor: colors.purple.base,
    borderColor: colors.purple.base,
  },
  {
    backgroundColor: colors.blueGrey.lighten1,
    dragBackgroundColor: colors.blueGrey.base,
    borderColor: colors.blueGrey.base,
  },
  {
    backgroundColor: colors.lightGreen.lighten1,
    dragBackgroundColor: colors.lightGreen,
    borderColor: colors.lightGreen,
  },
];

export function hexToRGB(hex: string, alpha?: string) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}
